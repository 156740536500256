import React, { Suspense } from 'react';
import { createBrowserRouter, createHashRouter } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';
import { routes as configRoutes } from './config';

const buildSyncRoute = (routes): RouteObject[] => {
  const syncRoutes: RouteObject[] = [];
  routes.forEach((route) => {
    const { component: Component, element, children, id, loader, ...others } = route;

    syncRoutes.push({
      ...others,
      id,
      // loader: async (...params) => {
      //   if (id === 'index') return loader?.(...params);

      //   const res = await loader?.(...params);
      //   // 传入当前菜单配置中的部分数据，供页面内直接通过useLoaderData获取
      //   return { title: route.title, ...(res || {}) };
      // },
      path: route.path,
      element: Component ? (
        <Suspense
          fallback={
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {/* <Spin /> */}
            </div>
          }
        >
          <Component />
        </Suspense>
      ) : (
        element
      ),
      // errorElement: <ErrorBoundary />,
      children: children && buildSyncRoute(children),
    });
  });

  return syncRoutes;
};

export default createBrowserRouter(buildSyncRoute(configRoutes));